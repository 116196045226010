<template>
  <div class="typing-dots">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  name: 'TypingDots'
}
</script>

<style lang="scss">
.typing-dots {
  display: flex;
  gap: 7.5px;
  padding: 30px 30px 30px 50px;

  span {
    animation: dots 1s ease-in-out infinite;
    background-color: #262a31;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    opacity: 0;
    width: 10px;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes dots {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.2);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
</style>