<template>
  <div class="message-container" :class="position">
    <span class="message-text" v-html="message"></span>
    <span class="ul-ui-text">{{ timePassed }}</span>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    message: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: 'bot'
    },
    timestamp: {
      type: Date,
      required: true
    }
  },
  computed: {},
  data() {
    return {
      timePassed: ''
    }
  },
  methods: {
    setTime() {
      const startTime = this.timestamp
      const endTime = new Date()
      const minutes = Math.floor((Math.abs(endTime.getTime() - startTime.getTime()) / (1000 * 60)) % 60)
      const hours = Math.floor((Math.abs(endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60)) % 24)
      const html = document.documentElement


      if (minutes === 0) {
        this.timePassed = 'weniger als eine Minute'
        if (html.getAttribute('lang') === 'en') this.timePassed = 'less then a minute ago'
      } else if (minutes < 60) {
        this.timePassed = `vor ${minutes} Minute${minutes === 1 ? '' : 'n'}`
        if (html.getAttribute('lang') === 'en') this.timePassed = `${minutes} minute${minutes === 1 ? '' : 's'} ago`
      } else if (hours < 24) {
        this.timePassed = `vor ${hours} Stunden${hours === 1 ? '' : 'n'}`
        if (html.getAttribute('lang') === 'en') this.timePassed = `${hours} hour${hours === 1 ? '' : 's'} ago`
      } else {
        this.timePassed = `${('0' + startTime.getDate()).slice(-2)}.${('0' + (startTime.getMonth() + 1)).slice(-2)}. ${('0' + startTime.getHours()).slice(-2)}:${(
          '0' + startTime.getMinutes()
        ).slice(-2)}`
      }
    }
  },
  mounted() {
    this.setTime()

    setInterval(() => {
      this.setTime()
    }, 60000)
  }
}
</script>

<style lang="scss">
.message-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 5px;
  padding: 30px;

  &.bot {
    align-items: flex-start;

    .message-text {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 10px;
    }
  }

  &.user {
    align-items: flex-end;

    .message-text {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 0px;
    }
  }

  .message-text {
    background-color: #ebebeb;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-block;
    padding: 10px;

    a {
      color: #262a31;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }

  .ul-ui-text {
    color: #676A6F;
    font-size: 12px;
  }
}
</style>
