<template>
  <div class="chatroom" :class="{ 'is-open': isOpen }" ref="chatroom">
    <header>
      <h2>{{ headerText }}</h2>
      <a role="button" @click="toggleIsOpen" @keydown.enter="toggleIsOpen" :title="toggleButtonTitle" tabindex="0">
        <span>{{ toggleButtonText }}</span>
      </a>
    </header>
    <massage-box
      :messages="messages"
      :isTyping="isTyping"
      ref="messageBox"
    ></massage-box>
    <div class="input">
      <chat-input
        :placeholder="placeholder"
        @keydown.native.enter.prevent="submit"
        v-model="inputText"
        ref="input"
      ></chat-input>
      <message-button
        :title="sendButtonTitle"
        :disabled="isTyping"
        @click.native="submit"
        @keydown.enter.native="submit"
        tabindex="0"
        >{{ sendButtonText }}</message-button
      >
    </div>
    <transition v-bind:name="'fade_' + $props.textBubblePosition">
      <div
        class="text-bubble"
        v-if="textBubbleVisible"
        @click="hideTextBubble"
        :title="toggleButtonTitle"
        ref="textBubble"
      >
        {{ bubbleText }}
      </div>
    </transition>
  </div>
</template>

<script>
import ChatInput from './ChatInput.vue'
import MassageBox from './MessageBox.vue'
import MessageButton from './MessageButton.vue'

export default {
  name: 'ChatRoom',
  components: {
    ChatInput,
    MassageBox,
    MessageButton
  },
  props: {
    bubbleText: {
      type: String,
      default: 'Chatbot'
    },
    headerText: {
      type: String,
      default: 'Chatbot'
    },
    host: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    textBubblePosition: {
      type: String,
      default: 'right'
    },
    welcomeMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputText: '',
      isOpen: false,
      isTyping: false,
      messages: [],
      textBubbleVisible: false,
      toggleButtonText: '',
      toggleButtonTitle: '',
      wasOpened: false,
      uuid: ''
    }
  },
  computed: {
    language: () => {
      return document.documentElement.getAttribute('lang')
    },
    sendButtonText: () => {
      const html = document.documentElement

      if (html.getAttribute('lang') === 'en') return 'Send'

      return 'Absenden'
    },
    sendButtonTitle: () => {
      const html = document.documentElement

      if (html.getAttribute('lang') === 'en') return 'Send message'

      return 'Nachricht abschicken'
    }
  },
  methods: {
    setButtonTitle() {
      const html = document.documentElement

      if (this.isOpen) {
        this.toggleButtonText = 'Chat minimieren'
        this.toggleButtonTitle = 'Chat minimieren'

        if (html.getAttribute('lang') === 'en') {
          this.toggleButtonText = 'Minimize chat'
          this.toggleButtonTitle = 'Minimize chat'
        }
      } else {
        this.toggleButtonText = 'Chat öffnen'
        this.toggleButtonTitle = 'Chat öffnen'

        if (html.getAttribute('lang') === 'en') {
          this.toggleButtonText = 'Open chat'
          this.toggleButtonTitle = 'Open chat'
        }
      }
    },
    setLinks(message) {
      const regex = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
      const matches = [...message.matchAll(regex)]
      let length = 0

      matches.forEach((match, index) => {
        const position = match.index
        const messageArray = message.split('')
        messageArray.splice(
          position + index * 31 + length,
          0,
          `<a href="${match[0]}" target="_blank">`
        )
        messageArray.splice(
          position + index * 31 + length + match[0].length + 1,
          0,
          '</a>'
        )
        message = messageArray.join('')
        length += match[0].length
      })

      return message
    },
    setTextBubblePosition() {
      const position = this.$props.textBubblePosition
      const textBubble = this.$refs.textBubble

      if (position === 'left') {
        textBubble.style.left = 'unset'
        textBubble.style.right = '25px'
        textBubble.style.borderBottomLeftRadius = '10px'
        textBubble.style.borderBottomRightRadius = '0'
      }
    },
    submit() {
      if (this.inputText.length && !this.isTyping) {
        this.messages.push({
          text: this.inputText,
          timestamp: new Date(),
          person: 'user'
        })

        const controller = new AbortController()

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ message: this.inputText, sender: this.uuid }),
          signal: controller.signal
        }

        this.isTyping = true
        this.inputText = ''

        fetch(this.host, requestOptions)
          .then(async (response) => {
            const data = await response.text()
            this.isTyping = false

            if (!response.ok) {
              const error = data || response.status
              return Promise.reject(error)
            }

            try {
              const jsonData = JSON.parse(data)

              if (Array.isArray(jsonData)) {
                for (const item of jsonData) {
                  await new Promise((resolve) => {
                    this.isTyping = true

                    this.$nextTick(() => {
                      this.$refs.messageBox.$el.scrollTop = this.$refs.messageBox.$el.scrollHeight
                    })

                    setTimeout(() => {
                      const text = this.setLinks(item.text)
                      this.messages.push({
                        text: text,
                        timestamp: new Date(),
                        person: 'bot'
                      })
                      this.isTyping = false
                      resolve()
                    }, 500)
                  })

                  this.$nextTick(() => {
                    this.$refs.messageBox.$el.scrollTop = this.$refs.messageBox.$el.scrollHeight
                  })
                }
              }
            } catch (err) {
              console.log(err)
            }
          })
          .catch((error) => {
            this.isTyping = false

            this.messages.push({
              text:
                'Es ist ein Fehler aufgetreten und die Eingabe konnte nicht beantwortet werden.',
              timestamp: new Date(),
              person: 'bot'
            })

            console.error('There was an error!', error)
          })

        setTimeout(() => controller.abort(), 5000)
      }
    },
    toggleIsOpen() {
      this.isOpen = !this.isOpen
      this.textBubbleVisible = false
    },
    hideTextBubble() {
      this.isOpen = !this.isOpen
      this.textBubbleVisible = false
    }
  },
  mounted() {
    this.setButtonTitle()

    if (!this.messages.length) {
      this.messages.push({
        text: this.welcomeMessage,
        timestamp: new Date(),
        person: 'bot'
      })
    }

    this.uuid = window.URL.createObjectURL(new Blob([])).substring(31)

    setTimeout(() => {
      if (!this.wasOpened) {
        this.textBubbleVisible = true
        this.$nextTick(() => {
          this.setTextBubblePosition()
        })

        setTimeout(() => {
          this.textBubbleVisible = false
        }, 8000)
      }
    }, 3000)
  },
  watch: {
    isOpen: {
      handler: function() {
        this.wasOpened = true
        this.setButtonTitle()

        if (this.isOpen && this.$refs.input && this.$refs.input.$el) {
          this.$refs.input.$el.focus()
        }
      }
    },
    language: {
      handler: function() {
        console.log('lang')
        const html = document.documentElement

        if (this.isOpen) {
          this.toggleButtonText = 'Chat minimieren'
          this.toggleButtonTitle = 'Chat minimieren'

          if (html.getAttribute('lang') === 'en') {
            this.toggleButtonText = 'Minimize chat'
            this.toggleButtonTitle = 'Minimize chat'
          }
        } else {
          this.toggleButtonText = 'Chat öffnen'
          this.toggleButtonTitle = 'Chat öffnen'

          if (html.getAttribute('lang') === 'en') {
            this.toggleButtonText = 'Open chat'
            this.toggleButtonTitle = 'Open chat'
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Futura';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('~@/assets/fonts/a3756428-debf-4a86-aed0-a834a3df9bd7.eot?#iefix');
  src: url('~@/assets/fonts/a3756428-debf-4a86-aed0-a834a3df9bd7.eot?#iefix')
      format('eot'),
    url('~@/assets/fonts/5f081b13-e570-4277-9283-091a6f6cab04.woff2')
      format('woff2'),
    url('~@/assets/fonts/588b3f3a-c193-4962-8e01-d4caef90f58b.woff')
      format('woff'),
    url('~@/assets/fonts/cdced636-4488-4c04-809c-e6e1379600ec.ttf')
      format('truetype'),
    url('~@/assets/fonts/34abe9e1-3810-4f50-9feb-63cd531bba3e.svg#34abe9e1-3810-4f50-9feb-63cd531bba3e')
      format('svg');
}

@font-face {
  font-family: 'Futura';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('~@/assets/fonts/18344c49-d09e-4b3c-b8a8-b8ab949de2f9.eot?#iefix');
  src: url('~@/assets/fonts/18344c49-d09e-4b3c-b8a8-b8ab949de2f9.eot?#iefix')
      format('eot'),
    url('~@/assets/fonts/a8f30db2-f049-4e3d-8025-2f848c97f096.woff2')
      format('woff2'),
    url('~@/assets/fonts/05f1af80-02cb-4d2b-992e-f7e9d678b7ad.woff')
      format('woff'),
    url('~@/assets/fonts/7fc6d4cf-507b-4169-b151-c4ad51e034ca.ttf')
      format('truetype'),
    url('~@/assets/fonts/38baa9f5-8717-437d-a2ad-2dd7c0db7965.svg#38baa9f5-8717-437d-a2ad-2dd7c0db7965')
      format('svg');
}

@font-face {
  font-family: 'Futura';
  font-weight: 700;
  font-display: swap;
  src: url('~@/assets/fonts/700bd18e-d9e0-45c5-93e2-7c930608c50e.eot?#iefix');
  src: url('~@/assets/fonts/700bd18e-d9e0-45c5-93e2-7c930608c50e.eot?#iefix')
      format('eot'),
    url('~@/assets/fonts/12dbb46a-1b0b-40ee-80a6-4141c349b55f.woff2')
      format('woff2'),
    url('~@/assets/fonts/2abd55ef-07d0-473e-80e1-0e4066cd01fa.woff')
      format('woff'),
    url('~@/assets/fonts/c4fa8988-43d1-4a01-96e4-36c5c5a121c9.ttf')
      format('truetype'),
    url('~@/assets/fonts/2ea6bace-93f3-4ab0-9f2d-77a466a20c7f.svg#2ea6bace-93f3-4ab0-9f2d-77a466a20c7f')
      format('svg');
}

@font-face {
  font-family: 'Futura';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('~@/assets/fonts/0e1bbf60-6f0d-4df8-bae6-c91e0112462c.eot?#iefix');
  src: url('~@/assets/fonts/0e1bbf60-6f0d-4df8-bae6-c91e0112462c.eot?#iefix')
      format('eot'),
    url('~@/assets/fonts/1f54c69d-9346-4402-abd9-d4e764a690e7.woff2')
      format('woff2'),
    url('~@/assets/fonts/d11e8b04-6f0c-4fcf-829a-0f3a6a7533ee.woff')
      format('woff'),
    url('~@/assets/fonts/b4e945dd-6acc-4f06-a215-1de6a0551643.ttf')
      format('truetype'),
    url('~@/assets/fonts/ac7d0937-7588-4213-8b74-83d0a314da39.svg#ac7d0937-7588-4213-8b74-83d0a314da39')
      format('svg');
}

@font-face {
  font-family: 'Futura';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('~@/assets/fonts/b2a8e0f7-fb89-4216-a216-b0d20c0a764f.eot?#iefix');
  src: url('~@/assets/fonts/b2a8e0f7-fb89-4216-a216-b0d20c0a764f.eot?#iefix')
      format('eot'),
    url('~@/assets/fonts/fb4c1bec-65b7-49c1-9cdb-2d38077a2256.woff2')
      format('woff2'),
    url('~@/assets/fonts/6daeefa5-3ccc-468f-8a4c-1f7458fdc723.woff')
      format('woff'),
    url('~@/assets/fonts/8e14af9b-4995-4326-ac2f-6ff4c9f4aca1.ttf')
      format('truetype'),
    url('~@/assets/fonts/cf7b6cc0-20fc-4c8a-b0f7-9b61c2f7f538.svg#cf7b6cc0-20fc-4c8a-b0f7-9b61c2f7f538')
      format('svg');
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.chatroom {
  background-color: #f0f0f0;
  color: #262a31;
  font: 15px 'Futura', 'Open Sans', arial, sans-serif;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 50px;
  max-width: 750px;
  transition: height 0.15s ease-in-out, width 0.15s ease-in-out 0.1s;
  width: 50px;

  &.is-open {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
    height: calc(100dvh - 20px);
    width: calc(100vw - 20px);
    transition: height 0.15s ease-in-out 0.1s, width 0.15s ease-in-out;

    header {
      a {
        background: no-repeat center center transparent;
        background-image: url('~@/assets/img/arrow_down_white.svg');
        background-size: 17.5px 17.5px;
        border: transparent;
        border-left: 2px solid #ffffff;
      }
    }
  }

  header {
    align-items: center;
    background-color: #262a31;
    color: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    height: 50px;

    h2 {
      flex-grow: 1;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
    }

    a {
      background: no-repeat center center transparent;
      background-image: url('~@/assets/img/chat.svg');
      background-size: 25px 25px;
      border: 2px solid #ffffff;
      cursor: pointer;
      flex-shrink: 0;
      height: 100%;
      width: 50px;

      &:focus,
      &:hover {
        background-color: #000000;
      }

      span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    }
  }

  .input {
    flex-grow: 0;
    display: flex;
    overflow: hidden;

    *:first-child {
      flex-grow: 1;
    }
  }

  .text-bubble {
    background-color: #ffffff;
    border: 1px solid #262a31;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    cursor: pointer;
    padding: 10px 15px;
    position: absolute;
    left: 25px;
    top: -75px;
    width: 270px;
  }
}

.fade_left-enter-active,
.fade_left-leave-active {
  transform-origin: bottom right;
  transition: all 0.5s;
}

.fade_left-enter,
.fade_left-leave-to {
  opacity: 0;
  transform: translate(0, 50%) scale(0);
}

.fade_right-enter-active,
.fade_right-leave-active {
  transform-origin: bottom left;
  transition: all 0.5s;
}

.fade_right-enter,
.fade_right-leave-to {
  opacity: 0;
  transform: translate(0, 50%) scale(0);
}
</style>
