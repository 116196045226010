// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/arrow_right_white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".msg-btn{cursor:pointer;background:#262a31 no-repeat 50%;border:0;border-radius:0;color:#fff;display:block;font-weight:700;letter-spacing:1px;line-height:1.375;max-width:365px;padding:14px 17px;text-align:center;text-transform:uppercase;transition:all .2s;white-space:normal}.msg-btn:focus{outline:1px dotted #000;outline-offset:2px}.msg-btn:focus,.msg-btn:hover{background-color:#000}@media(max-width:767px){.msg-btn{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:50px}.msg-btn span{border:0;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}}@media(min-width:1024px){.msg-btn{font-size:16px}}", ""]);
// Exports
module.exports = exports;
