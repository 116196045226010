<template>
  <a class="msg-btn" role="button">
    <span>
      <slot />
    </span>
  </a>
</template>

<script>
export default {
  name: 'MessageButton'
}
</script>

<style lang="scss">
.msg-btn {
  cursor: pointer;
  background: #262a31 no-repeat center center;
  border: 0;
  border-radius: 0;
  color: #ffffff;
  display: block;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.375;
  max-width: 365px;
  padding: 14px 17px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s;
  white-space: normal;

  &:focus {
    outline: 1px dotted #000000;
    outline-offset: 2px;
  }

  &:focus,
  &:hover {
    background-color: #000000;
  }
}

@media (max-width: 767px) {
  .msg-btn {
    background-image: url('~@/assets/img/arrow_right_white.svg');
    width: 50px;

    span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}

@media (min-width: 1024px) {
  .msg-btn {
    font-size: 16px;
  }
}
</style>