<template>
  <div class="message-box">
    <template v-for="(message, index) in messages">
      <message :position="message.person === 'user' ? 'user' : 'bot'" :timestamp="message.timestamp" v-bind:key="index" :message="message.text"></message>
    </template>
    <typing-dots v-if="isTyping"></typing-dots>
  </div>
</template>

<script>
import Message from './Message.vue'
import TypingDots from './TypingDots.vue'

export default {
  name: 'MessageBox',
  components: {
    Message,
    TypingDots
  },
  props: {
    isTyping: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.message-box {
  background-color: #fff;
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: 10px;
  overflow-y: scroll;
  //overscroll-behavior-y: contain;
  //scroll-snap-type: y proximity;

  > *:last-child {
    scroll-snap-align: end;
  }
}
</style>
