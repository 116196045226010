<template>
  <input class="chat-input" :placeholder="placeholder" v-model="model" />
</template>

<script>
export default {
  name: 'ChatInput',
  props: {
    value: String,
    modelValue: String,
    placeholder: String
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
.chat-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-color: #ffffff;
  background-image: none;
  box-shadow: none !important;
  border: 1px solid #ffffff;
  border-radius: 0;
  color: #262a31;
  display: block;
  font-family: inherit;
  font-size: 16px;
  height: 50px;
  line-height: 20px;
  overflow: hidden;
  padding: 14px 19px;
  text-overflow: ellipsis;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:hover {
    border: 1px solid #507d8e;
    outline: 0;
  }

  &:focus {
    border: 1px solid #676a6f;
    outline: 0;
  }
}
</style>